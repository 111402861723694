import React, { useState, useContext, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import GeneralContext from "../GeneralContext/GeneralContext";
import Grid from "@mui/material/Grid2";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";

import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


import { NumericFormat } from "react-number-format";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import axios from "../../api/axios";
import Alert from "@mui/material/Alert";
import { Divider } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import PageBgBlock from "../GeneralComponents/PageBgBlock";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import InputRut from "../GeneralComponents/InputRut";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import ModalStandard from "../GeneralComponents/ModalStandard";

import { useNavigate } from "react-router-dom";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ParqueAdd() {
  const { setPageTitle, setDrawerHidding, userSesion } =
    useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [techTypes, setTechTypes] = useState([]);
  const [RBDList, setRBDList] = useState([""]);
  const [submitConfirm, setSubmitConfirm] = useState(false);
  const [rbdLoading, setRbdLoading] = React.useState(false);
  const [autoCompleteInput, setAutoCompleteInput] = React.useState("");
  const fusRef = useRef();
  const autoCompleteRef = useRef(null);
  const rutRef = useRef(null);
  const evidenceRef = useRef(null);
  const [evError, setEvError] = useState(true);
  const [error, setError] = useState("");
  const [errorRut, setErrorRut] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [loadingPdf, setLoadingPdf] = useState(false);


  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    rut_rep_1: "",
    nombre_rep_1: "-",
    rut_rep_2: "",
    nombre_rep_2: "-",
    operator_name: "",
    rbd: "",
    ees_name: "",
    latitud: "",
    longitud: "",
    jornada_a: false,
    jornada_b: false,
    jornada_c: false,
    jornada_d: false,

  });

    const validateForm = () => {
    const newErrors = {};
    if (!formData.nombre.trim()) {
      newErrors.nombre = "El nombre es requerido";
    }

    if (!formData.rut.trim()) {
      newErrors.rut = "El RUT es requerido";
    }

    if (
      formData.telefono.trim() &&
      !/^\d{9}$/.test(formData.telefono)
    ) {
      newErrors.telefono = "Debe ser un número de teléfono válido de 9 dígitos";
    }

    if (
      formData.direccion.trim() &&
      formData.direccion.trim().length <= 10 
    ) {
      newErrors.direccion = "La dirección debe tener al menos 10 caracteres";
    }

    if (
      !formData.email.trim() ||
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)
    ) {
      newErrors.email = "E-mail inválido";
    }

    return newErrors;
  };

    const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

    const handleCheckboxChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };

  const handleErrorChange = (error) => {
    setErrorRut(error);
  };

  const handleDownloadPdf = () => {
    const element = fusRef.current;

    setLoadingPdf(true);
    html2canvas(element, { scale: 2 })
      .then((canvas) => {
        //const imageData = canvas.toDataURL('image/png');
        const imageData = canvas.toDataURL("image/jpeg", 1);

        const pdfWidth = 210;
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight + 140]);

        pdf.setFillColor(255, 255, 255);
        pdf.rect(0, 0, pdfWidth, pdfHeight + 140, "F");

        pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);

        const pdfBlob = pdf.output("blob");

        const form = new FormData();
        const localFormData = {
          ...formData,
          installation_date: formData["installation_date"]
            .locale("es")
            .format("YYYY/MM/DD HH:MM:ss")
            .toString(),
          rbd: autoCompleteInput ? autoCompleteInput : "",
        };

        form.append("data", JSON.stringify(localFormData));
        for (let i = 0; i < localFormData["images"].length; i++) {
          form.append("evidence", localFormData["images"][i]);
        }
        form.append("file", pdfBlob, "temp.pdf");

        axios
          .sendFilesFus(form)
          .then((response) => {
            setLoading(false);
            setSubmitConfirm(!submitConfirm);
            setModalError(false);
            setModalContent("Documento enviado y firmado");
            setOpen(true);
          })
          .catch((error) => {
            setModalError(true);
            setModalError("La creacion del documento a fallado");
            setLoading(false);
          });
        setLoadingPdf(false);
      })
      .catch((error) => {
        console.error("Error generando el PDF:", error);
        setLoadingPdf(false);
        throw error;
      });
  };

  function getFile() {
    handleDownloadPdf();
  }

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const uniqueFiles = files.filter(
      (file) => !formData.images.some((img) => img.name === file.name)
    ); // Evitar duplicados basados en el nombre del archivo
    setFormData({
      ...formData,
      images: [...formData.images, ...uniqueFiles],
    });
    setEvError(false);
  };

  const handleRemoveImage = (imageName) => {
    const updatedImages = formData.images.filter(
      (image) => image.name !== imageName
    );
    setFormData({
      ...formData,
      images: updatedImages,
    });
    if (updatedImages.length === 0) {
      setEvError(true);
    }
  };

  function getRBD() {
    if (autoCompleteInput !== "") {
      let rbd_temp = autoCompleteInput;
      setRbdLoading(true);
      axios
        .getRBD(rbd_temp)
        .then((response) => {
          setRBDList(response.data.rbd_list);
          setRbdLoading(false);
        })
        .catch((error) => {
          setRbdLoading(false);
        });
    }
  }


  function handleClick(event) {
    event.preventDefault();
    if (formData.images.length === 0) {
      setEvError(formData.images.length === 0 ? true : false);
      evidenceRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }

    if (error) {
      autoCompleteRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    if (errorRut) {
      rutRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }
    if (submitConfirm) {
      setLoading(true);
      try {
        getFile();
      } catch (error) {
        console.log("Error ", error);
        setLoading(false);
        setSubmitConfirm(!submitConfirm);
      }
    } else {
      setSubmitConfirm(!submitConfirm);
    }
  }
  const handleSwitch = (event) => {
    setFormData({ ...formData, checked: event.target.checked });
  };
  function handleDropDown(value) {
    setFormData({ ...formData, tech_id: value });
  }

  useEffect(() => {
    setDrawerHidding(false);
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      rbd: autoCompleteInput ? autoCompleteInput : "",
    });
    getRBD();
  }, [autoCompleteInput]);

  if (pageLoaded) {
    return (
      <>
        
      <PageBgBlock >
          <Stack sx={{ background: "#FFFFFF", borderRadius: "0.5rem" }}>
            <Box
              ref={fusRef}
            >
              <Stack alignItems="left" justifyContent="center" spacing={2}>
                <Typography
                  variant="h1"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
                >
                  MODIFICAR EES A ESTADO DE PARQUE
                </Typography>

                <Box
                  justifyContent="center"
                  sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
                >
                  <Typography
                    variant="body"
                    align="center"
                    sx={{ marginBottom: "2rem", maxWidth: "45rem" }}
                  >
                    Para <b>modificar</b> establecimientos al Estado de Parque actual llene los campos a editar, cuide que la información esté correcta antes de agregar.
                  </Typography>
                </Box>

                <form onSubmit={handleClick} id="parque">
                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                          id="outlined-required"
                          label="N° Oficio"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.oficio}
                          onChange={(newValue) =>
                            setFormData({
                              ...formData,
                              oficio: newValue.target.value,
                            })
                          }
                        />
                        </FormControl>
                      </Grid>
                    </Grid>

                  <Divider orientation="horizontal" flexItem sx={{margin: "1rem 0"}}/>

                    
                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                          required
                          id="outlined-required"
                          label="RBD"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.rbd}
                          onChange={(newValue) =>
                            setFormData({
                              ...formData,
                              rbd: newValue.target.value,
                            })
                          }
                        />
                        </FormControl>
                      </Grid>


                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                          <InputLabel
                            id="severidad-label"
                            sx={{ backgroundColor: "#fff" }}
                          >
                            Tipo de establecimiento
                          </InputLabel>
                          <Select
                            labelId="severidad-label"
                            id="severidad"
                            value={formData.tipo_ees}
                            label="Tipo de establecimiento"
                            size="small"
                            sx={{ width: "100%" }}
                            onChange={handleInputChange}
                          >

                            <MenuItem value={"Tipo 1"}>Tipo 1</MenuItem>
                            <MenuItem value={"Tipo 2"}>Tipo 2</MenuItem>
                          </Select>
                        </FormControl>
                    </Grid>

                    <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                            required
                          id="outlined-required"
                          label="Nombre"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.nombre_ees}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                            required
                          id="outlined-required"
                          label="Dirección"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.direccion_ees}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>

                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                        required
                          id="outlined-required"
                          label="RUT Representante EES 1"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.rut_rep_1}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                          id="outlined-required"
                          label="RUT Representante EES 2"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.rut_rep_2}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>

                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                        required
                          id="outlined-required"
                          label="Nombre Representante EES 1"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.nombre_rep_1}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                          id="outlined-required"
                          label="Nombre Representante EES 2"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.nombre_rep_2}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="telefono"
                      label="Teléfono"
                      size="small"
                      value={formData.telefono}
                      onChange={handleInputChange}
                      error={!!errors.telefono}
                      helperText={errors.telefono}
                    />
                  </FormControl>
                </Grid>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="email"
                      label="E-mail"
                      size="small"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </FormControl>
                </Grid>
                      <Grid size={6}>
                        <NumericFormat
                          customInput={TextField}
                          label="Latitud"
                          value={formData.latitud}
                          onValueChange={(newValue) =>
                        setFormData({
                          ...formData,
                          latitud: newValue,
                        })
                      }
                          allowNegative={false}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid size={6}>
                        <NumericFormat
                          customInput={TextField}
                          label="Longitud"
                          value={formData.longitud}
                          onValueChange={(newValue) =>
                        setFormData({
                          ...formData,
                          longitud: newValue,
                        })
                      }
                          allowNegative={false}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid size={6}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={"es"}
                          localeText={
                            esES.components.MuiLocalizationProvider.defaultProps
                              .localeText
                          }
                        >
                          <DateTimePicker
                            label="Fecha de Ingreso"
                            slotProps={{ textField: { size: "small" } }}
                            value={formData.fecha_ingreso}
                            size="small"
                            sx={{ width: "100%" }}
                            onChange={(newValue) =>
                              setFormData({
                                ...formData,
                                fecha_creacion: newValue,
                              })
                            }
                            disableFuture
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid size={6}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={"es"}
                          localeText={
                            esES.components.MuiLocalizationProvider.defaultProps
                              .localeText
                          }
                        >
                          <DateTimePicker
                            label="Fecha modificación"
                            slotProps={{ textField: { size: "small" } }}
                            value={formData.fecha_modificacion}
                            size="small"
                            sx={{ width: "100%" }}
                            onChange={(newValue) =>
                              setFormData({
                                ...formData,
                                fecha_actualizacion: newValue,
                              })
                            }
                            disableFuture
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid size={6}>
              <Box ref={autoCompleteRef}>
                <FormControl sx={{ minWidth: "100%" }}>
                        <Autocomplete
                            key="rbd"
                            value={autoCompleteInput}
                            onChange={(event, newValue) => {
            setAutoCompleteInput(newValue ? newValue : "");
            setFormData({ ...formData, rbd: newValue ? newValue : "" });
        }}
                            inputValue={autoCompleteInput}
                            onInputChange={(event, newValue) => {
            setAutoCompleteInput(newValue ? newValue : "");
            setFormData({ ...formData, rbd: newValue ? newValue : "" });
        }}
                            disablePortal
                            loading={rbdLoading}
                            loadingText="Cargando..."
                            multiple={false}
                            options={RBDList}
                            //blurOnSelect
                            autoComplete
                            size='small'
                            //onBlur={getEes}
                            renderInput=
                            {
                                (params) => {
                                    return (
                                        <TextField required
                                            //onChange={(event) => handleAutoRBDchange(event, 1)}
                                            error={!!errors.id_comuna} 
                                            helperText={errors.id_comuna}
                                            {...params} label="ID Comuna" />
                                    )
                                }
                            }
                        />
                    </FormControl>
                    </Box>
              </Grid>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                        disabled
                      name="id_zona"
                      label="ID Zona"
                      size="small"
                      value={formData.id_zona}
                      onChange={handleInputChange}
                      error={!!errors.id_zona}
                      helperText={errors.id_zona}
                    />
                  </FormControl>
                </Grid>

         <Grid size={4}>

                  <Typography
                    variant="body"
                  >
            Tipo de jornada:
            </Typography>
         </Grid>       

        <Grid size={2}>
          <FormControlLabel 
            control={
              <Checkbox checked={formData.jornada_a} onChange={handleCheckboxChange} name="jornada_a" />
            }
            label="A"
          />

          </Grid>
        <Grid size={2}>
          <FormControlLabel  
            control={
              <Checkbox checked={formData.jornada_b} onChange={handleCheckboxChange} name="jornada_b" />
            }
            label="B"
          />
          </Grid>
        <Grid size={2}>
          <FormControlLabel
            control={
              <Checkbox checked={formData.jornada_c} onChange={handleCheckboxChange} name="jornada_c" />
            }
            label="C"
          />
          </Grid>
        <Grid size={2}>
          <FormControlLabel
            control={
              <Checkbox checked={formData.jornada_d} onChange={handleCheckboxChange} name="jornada_d" />
            }
            label="D"
          />
          </Grid>




                    </Grid>


                </form>
              </Stack>
            </Box>
          </Stack>

          <Box sx={{margin: "2rem auto 0"}}>
            <PageButtonSimple
                        form="ees"
                        startIcon={<AddCircleOutlineOutlinedIcon/>}
                        loading={loading}
                      >
                        Agregar establecimiento
                      </PageButtonSimple>
          </Box>
        </PageBgBlock>

        <ModalStandard
          open={open}
          error={modalError}
          title={modalContent}
          btnText="Volver a inicio"
          onClick={() => navigate("/home")}
          onClose={handleClose}
        ></ModalStandard>
      </>
    );
  } else {
    return <Box></Box>;
  }
}
