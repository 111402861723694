import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import InputFake from "../GeneralComponents/InputFake";
import InputRut from "../GeneralComponents/InputRut";
import InputFile from "../GeneralComponents/InputFile";
import SignatureFooter from "../GeneralComponents/SignatureFooter";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ApelacionHiddenPreview({formData, evidence, reference}) {
  return (
    <>
    <PageBody>
      <Box ref={reference} sx={{width: "1024px"}}>
      <PageBgBlock pad="2rem 9rem" > 
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: "0.24rem" }}
        >
          FORMULARIO APELACIÓN PRE-FACTURA
        </Typography>
        <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  En este formulario deberá justificar su factura proporcionando una descripción clara del motivo de la discrepancia. Es importante incluir toda la evidencia relevante que respalde su apelación, como documentos, correos electrónicos o comprobantes, para que su caso pueda ser evaluado de manera justa y objetiva.
          </Typography>
        </Box>

        <form id="contingencia">
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>

<Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    name="id_operador"
                    label="ID Operador"
                    size="small"
                    value={formData.id_operador}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    name="operator_name"
                    label="Nombre Operador"
                    size="small"
                    value={formData.operator_name}
                  />
                </FormControl>
              </Grid>
            </Grid>



            <Grid container spacing={2}>

              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        required
                        disabled
                        label="Periodo"
                        size="small"
                        value={formData.periodo}
                        sx={{ width: "100%" }}
                    />
                </FormControl>

                            </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        required
                        disabled
                        label="Zona"
                        size="small"
                        value={formData.zona}
                        sx={{ width: "100%" }}
                    />
                </FormControl>
              </Grid>

                            

            </Grid>

          
            
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DESCRIPCIÓN DE LA APELACIÓN
            </Typography>



              <InputFake value={formData.motivo} ></InputFake>


            


           
            <Box >
            <InputFile onFileSelect={() => {}} initialFile={evidence} fileType="pdf" placeholder="Subir evidencia (formato PDF)" />
            <Typography
              variant="small" align="left"
              sx={{ marginBottom: "18px", color:'#111' }}
            >
             Máximo 25mb 
            </Typography>
            </Box>
          </Stack>

        </form>
      </PageBgBlock>
      <SignatureFooter ees={false}></SignatureFooter>
          </Box>
      </PageBody>

        

            </>
  );
}
