import { Box, Button } from "@mui/material";

import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";

export default function SignatureFooter({mineduc = false, ees = true}) {


    return (
        <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: "15px", paddingTop: "80px" }}>
          <Grid size={4} align="center">
             <Box
                  sx={{
                    borderBottom: "2px solid black",
                    width: "60%",
                    margin: "0 auto",
                    paddingTop: "60px",
                  }}
                />
            <Typography variant="small" sx={{  }} >
              Firma Operador
            </Typography>
          </Grid>
          { ees ? ( <Grid size={4} align="center">
                       <Box
                  sx={{
                    borderBottom: "2px solid black",
                    width: "60%",
                    margin: "0 auto",
                    paddingTop: "60px",
                  }}
                />
            <Typography variant="small" sx={{  }} >
              Firma EES
            </Typography>
        </Grid>) : null}
          <Grid size={4} align="center">
                       <Box
                  sx={{
                    borderBottom: "2px solid black",
                    width: "60%",
                    margin: "0 auto",
                    paddingTop: "60px",
                  }}
                />
            <Typography variant="small" sx={{  }} >
              Firma Subtel
            </Typography>    
          </Grid>
          { mineduc ? (<Grid size={4} align="center">
                       <Box
                  sx={{
                    borderBottom: "2px solid black",
                    width: "60%",
                    margin: "0 auto",
                    paddingTop: "80px",
                  }}
                />
            <Typography variant="small" sx={{  }} >
              Firma Mineduc
            </Typography>
          </Grid>) : null }
            
        </Grid>
    );
}
