import React, { useState } from "react";
import { 
  Card, CardHeader, Avatar, Chip, Divider, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Grid, Button, Typography, Box 
} from '@mui/material';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from 'react-router-dom';
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import { Download as DownloadIcon } from "@mui/icons-material";

export default function PrefacturaCard({ data }) {
  const navigate = useNavigate();
  const [loadingPDF, setLoadingPDF] = useState(false);

  const downloadPDF = (url) => {
    setLoadingPDF(true);
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = blobUrl;
        a.download = 'download.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
        setLoadingPDF(false);
      })
      .catch((error) => {
        setLoadingPDF(false);
        console.error('Error al descargar el archivo:', error);
        window.open(url, '_blank');
      });
  };

  return (
    <Card sx={{ maxWidth: 400, marginRight: 2, marginBottom: 2, padding: 1 }}>
      {/* Cabecera con avatar, nombre, fecha y chip */}
      <CardHeader
        sx={{ padding: 1 }}
        avatar={
          <Avatar
            src={data.avatar}
            sx={{ bgcolor: 'primary.main', borderRadius: 2, width: 40, height: 40 }}
          />
        }
        title={
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1" fontWeight="bold">{data.id_operador}</Typography>
            <Typography variant="body2" color="textSecondary">{data.periodo_facturacion}</Typography>
          </Box>
        }
        action={
          <Box display="flex" flexDirection="column" alignItems="flex-end" sx={{ mt: 1  }}>
            <Chip label={data.diff} size="small" sx={{ backgroundColor: data.color }} />
            <Typography variant="body2" color="textSecondary">
              <div style={{ display: "flex", alignItems: "center", marginRight: 4 }}>
                <FiberManualRecordIcon
                  sx={{
                    fontSize: ".75rem",
                    color:
                      data.estado_validacion === "En Apelación" || data.estado_validacion === "Pendiente"
                        ? "#FFE263"
                        : data.estado_validacion === "Rechazado"
                        ? "#FB3B3B"
                        : "#41AE43",
                    marginRight: "0.5rem",
                  }}
                />
                {data.estado_validacion}
              </div>
            </Typography>
          </Box>
        }
      />

      <Divider sx={{ my: 1 }} />

      {/* Tabla con scroll estilizado */}
      <TableContainer
        sx={{
          height: '12rem',
          overflow: 'auto',
          scrollbarWidth: 'thin', 
          '&::-webkit-scrollbar': { width: '8px' }, 
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: 'none' }}>Zona</TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>EES</TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>Implementación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.details.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ borderBottom: 'none' }}>{row.zona}</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>{row.ees}</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>{row.implementacion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Divisor */}
      <Divider sx={{ my: 2 }} />

      {/* Botones sección 1 */}
      <Grid container spacing={0} justifyContent="space-between">
        <Grid item>
          <PageButtonSimple
            width="10rem"
            pad="0.5rem"
            fs="0.6875rem"
            startIcon={<DownloadIcon />}
            onClick={(e) => {
              e.stopPropagation();
              downloadPDF(data.link_prefactura_sagec);
            }}
          >
            SAGEC prefactura
          </PageButtonSimple>
        </Grid>
        <Grid item>
          <PageButtonSimple
            width="10rem"
            pad="0.5rem"
            fs="0.6875rem"
            startIcon={<DownloadIcon />}
            onClick={(e) => {
              e.stopPropagation();
              downloadPDF(data.link_prefactura_sagec_adicional);
            }}
          >
            Operador prefactura
          </PageButtonSimple>
        </Grid>
      </Grid>

      {/* Divisor */}
      <Divider sx={{ my: 2 }} />

      {/* Texto y botones sección 2 */}
      <Box sx={{ padding: 1 }}>
        <Typography variant="body2" align="center" color="textSecondary" sx={{ mb: 2 }}>
          <b>Código de aprobación prefactura: -</b>
        </Typography>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <PageButtonWhite
              loading={data.estado_validacion !== "En Apelación"}
              width="9rem"
              pad="0.5rem 1rem"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/apelacion_validacion/" + data.id);
              }}
            >
              Ir a apelación
            </PageButtonWhite>
          </Grid>
          <Grid item>
            <PageButtonBlack
              loading={data.estado_validacion === "En Apelación" || data.estado_validacion === "Pendiente" || data.estado_validacion === "Finalizado"}
              width="9rem"
              pad="0.5rem 1rem"
              onClick={(e) => {
                e.stopPropagation();
                alert("generar prefactura");
              }}
            >
              Generar código
            </PageButtonBlack>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
