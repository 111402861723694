import axios from 'axios';
const BASE_URL = process.env.REACT_APP_ROOT_URL;
//const BASE_URL =  '/api';

 
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Cache-Control": "no-cache",'Content-Type': 'application/json'
    },
    withCredentials: true,
    showSnackbarOnSuccess: null,
    showSnackbarOnError: true,
});


export default {
    getUserLogin: (jwt) =>
    axiosPrivate({
        'method':'GET',
        'url':'/auth/user', 
        headers: {
          'Authorization': 'Bearer '+ jwt,
        }, 
        
    }),
    getUser: () =>
    axiosPrivate({
        'method':'GET',
        'url':'/auth/user', 

    }),
    verify: () =>
    axiosPrivate({
        'method':'GET',
        'url':'/auth/verify'
    }),
    sendFilesFas: (data) =>
    axiosPrivate({
        'method':'POST',
        'url': '/service/esdb/create/fas',
        'data': data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        'showSnackbarOnSuccess': "Fas enviado y firmado",

    }),
    sendFilesFus: (data) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/create/fus',
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Fus enviado y firmado",
  
      }),
    sendFilesContingencias: (data) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/create/contingencias',
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Contingencia enviada y firmada",
      }),
    sendFilesApelacion: (data) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/create/apelacion',
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Apelación enviada y firmada",
      }),
      downloadDocuments: (docType, docsId) =>
        axiosPrivate({
            method: 'POST',
            url: `/service/esdb/download_documents/${docType}`,
            data: docsId,
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
        }),
    getContingenciasData: () =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/get_contingencias', 
        showSnackbarOnError: false,
    }),
    getContingencia: (id) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/get_contingencias_by_id/'+id, 
        showSnackbarOnError: false,
    }),
    getEesData: (rbd, jwt) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/info_ees_fas/'+rbd+'/'+jwt+'', 
        showSnackbarOnError: false,
    }),
      rejectDoc: (doc_type, id, data) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/reject/'+doc_type+'/'+id,
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Documento rechazado correctamente",
  
      }),
      signDoc: (doc_type, id, data) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/sign_document/'+doc_type+'/'+id,
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Documento firmado correctamente",
  
      }),
      getEesByRut: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/info_ees_por_rrpp_ees/'+rut, 
        showSnackbarOnError: false,
    }),
    getEes: (rbd) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/ees/'+rbd, 
        showSnackbarOnError: false,
    }),
    getUUID: (uuid) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/ardb/'+uuid, 
    }),
    getUserRBD: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/usuarios_ees/'+rut, 
    }),
    getRBD: (rbd) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/rbd_autocomplete/'+rbd, 
    }),
    getFas: (id) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/fas/'+id, 
        showSnackbarOnError: false,
    }),
    getFus: (id) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/fus/'+id, 
        showSnackbarOnError: false,

    }),
    getFasList: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/fas_endpoint/'+rut+'/1', 
    }),
    getFusList: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/fus_endpoint/'+rut+'/1', 
    }),
    getOperator: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/operador_por_operador_instalador/'+rut, 
    }),
    getTechTypes: () =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/tipos_tecnologia/', 
    }),
    getPrefacturas: () =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/prefacturas/completas', 
    }),
      acceptPrefactura: (id) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/accept_prefactura/'+id,

      }),
    getPrefactura: (query) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/prefactura/'+query, 
    }),
    getApelaciones: () =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/apelaciones/', 
    }),
    getApelacion: (query) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/apelaciones/'+query, 
    }),

    getReportesComerciales: (query) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/reportes_comerciales/'+query, 
    }),
    putConfigUser: (rut, data) =>
      axiosPrivate({
        'method':'PUT',
        'url':'/service/cedb/configuracion_usuario/'+rut, 
        'data': data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        'showSnackbarOnSuccess': "Operación exitosa",
    }),
    putConfigUserSign: (rut, data) =>
      axiosPrivate({
        'method':'PUT',
        'url':'/service/cedb/configuracion_usuario/'+rut+'/firma',
        'data': data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
    }),


}
