import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Divider,
  TextField,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import PageBgBlock from "../GeneralComponents/PageBgBlock";

export default function ParqueDelete() {
  const { pageTitle, setPageTitle } = useContext(GeneralContext);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [RBDList, setRBDList] = useState([]);
  const [rbdLoading, setRbdLoading] = useState(false);
  const [oficio, setOficio] = useState("");

  const [autoCompleteInput, setAutoCompleteInput] = useState("");
  const [eesData, setEesData] = useState(null);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // Obtiene los datos de EES por RBD
  function getEes() {
    if (autoCompleteInput !== "") {
      axios
        .getEes(autoCompleteInput, "1")
        .then((response) => {
          setEesData(response.data);
          setError(false);
          setErrorMsg("");
        })
        .catch(() => {
          setEesData(null);
          setError(true);
          setErrorMsg("Colegio no encontrado.");
        });
    }
  }

  // Obtiene la lista de RBDs
  function getRBD() {
    if (autoCompleteInput !== "") {
      setRbdLoading(true);
      axios
        .getRBD(autoCompleteInput)
        .then((response) => {
          setRBDList(response.data.rbd_list);
          setRbdLoading(false);
          setError(false);
          setErrorMsg("");
          getEes();
        })
        .catch(() => {
          setRBDList([]);
          setRbdLoading(false);
          setError(true);
          setErrorMsg("Colegio no encontrado.");
        });
    }
  }

  // Llama a getRBD cada vez que cambia el input del autocomplete
  useEffect(() => {
    if (autoCompleteInput) {
      getRBD();
    }
  }, [autoCompleteInput]);

const handleAddRow = () => {
    // Validación de carga y errores
    if (!eesData || error) {
      alert(
        "No se pueden agregar datos. Verifique que el establecimiento exista y que no haya errores."
      );
      return;
    }

    // Validación de duplicados
    const alreadyExists = rows.some((row) => row.rbd === eesData.rbd);
    if (alreadyExists) {
      alert("El establecimiento ya está agregado en la tabla.");
      return;
    }

    // Agregar nueva fila
    setRows((prevRows) => [
      ...prevRows,
      {
        rbd: eesData.rbd,
        nombre: eesData.nombre,
        operador: eesData.operador,
        id_zona: eesData.id_zona,
        motivoBaja: "",
      },
    ]);

    // Limpieza del estado
    setAutoCompleteInput("");
    setEesData(null);
    setError(false);
  };

  // Maneja el cambio en el motivo de baja
  const handleMotivoChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].motivoBaja = value;
    setRows(updatedRows);
  };

  // Maneja el envío de los datos
  const handleSend = () => {
    if (rows.length === 0) {
      alert("Debe agregar al menos un elemento a la tabla.");
      return;
    }

    console.log("Datos enviados:", rows);
    alert("Datos enviados exitosamente.");
  };

  const removeEES = () => {
    setLoading(true);
    alert("Eliminar establecimiento");
    setLoading(false);
  };

  useEffect(() => {
    setPageTitle("Listado de reportes");
  }, [pageTitle, setPageTitle]);

  return (
    <>
      <PageBgBlock>
        <Stack alignItems="left" justifyContent="center" spacing={2}>
          <Typography
            variant="h1"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
          >
            ELIMINAR EES A ESTADO DE PARQUE
          </Typography>
          <Box
            justifyContent="center"
            sx={{
              maxWidth: "100%",
              margin: "0 auto",
              display: "flex",
            }}
          >
            <Typography
              variant="body"
              align="center"
              sx={{ marginBottom: "3rem", maxWidth: "45rem" }}
            >
              Para <b>eliminar</b> establecimientos al Estado de Parque busque
              el número de RBD, al tener el resultado presione Eliminar.
            </Typography>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl sx={{ minWidth: "100%" }}>
              <Autocomplete
                value={autoCompleteInput}
                onChange={(event, newValue) => setAutoCompleteInput(newValue || "")}
                inputValue={autoCompleteInput}
                onInputChange={(event, newValue) => setAutoCompleteInput(newValue || "")}
                disablePortal
                loading={rbdLoading}
                loadingText="Cargando..."
                options={RBDList}
                autoComplete
                size="small"
                renderInput={(params) => (
                  <TextField
                    required
                    error={error}
                    helperText={errorMsg}
                    {...params}
                    label="RBD"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>

          <PageButtonSimple
                        pad=".5rem"
                        width="auto"
                        onClick={handleAddRow}
                        startIcon={<AddCircleOutlineOutlinedIcon/>}
                        disabled={!autoCompleteInput || rbdLoading}
                      >
                        Agregar a la lista
                      </PageButtonSimple>


          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mb: 2, mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>RBD</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Operador</TableCell>
                <TableCell>ID Zona</TableCell>
                <TableCell>Motivo Baja</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ verticalAlign: "top" }}>{row.rbd}</TableCell>
                  <TableCell sx={{ verticalAlign: "top" }}>{row.nombre}</TableCell>
                  <TableCell sx={{ verticalAlign: "top" }}>{row.operador}</TableCell>
                  <TableCell sx={{ verticalAlign: "top" }}>{row.id_zona}</TableCell>
                  <TableCell>
                    <TextField
                      required
                      multiline
                      minRows={2}
                      maxRows={4}
                      size="small"
                      sx={{ width: "100%" }}
                      value={row.motivoBaja}
                      onChange={(e) => handleMotivoChange(index, e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider sx={{ my: 2 }} />





            <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
                    <TextField
                      name="oficio"
                      label="N° de Oficio"
                      size="small"
                      value={oficio}
                      onChange={(event) => {setOficio(event.target.name)}}
                    />
                  </FormControl>
          </Grid>
          <Grid item xs={6}>
              <Typography
                    variant="small"
                  >
                  *Por favor, en el campo “N° Oficio” ingrese el número del oficio recibido por correo. Este número es importante para fines administrativos y debe registrarse correctamente. Ante cualquier duda, no dude en contactarnos a través de los canales oficiales.
                  </Typography>
            
          </Grid>
        </Grid>


         <Box sx={{margin: "auto", marginTop: "2rem"}}>
            <PageButtonSimple
                        onClick={handleSend}
                        startIcon={<RemoveCircleOutlineOutlinedIcon/>}
                        loading={loading}
                        style="warning"
                      >
                        Eliminar establecimiento (Baja EES)
                      </PageButtonSimple>
          </Box>
      </PageBgBlock>
    </>
  );
}
