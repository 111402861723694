import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import GeneralContext from "../GeneralContext/GeneralContext";
import Grid from "@mui/material/Grid2";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { Radio, RadioGroup } from "@mui/material";

import { NumericFormat } from "react-number-format";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import axios from "../../api/axios";
import { Divider } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import ImageGallery from "../GeneralComponents/ImageGallery";

import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";

import ModalStandard from "../GeneralComponents/ModalStandard";
import { useNavigate } from "react-router-dom";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";

import {
  REJECT_F,
  NO_EES,
  NO_EES_TEMP,
  NO_SUBTEL,
  NO_MINEDUC,
  ROL_REP_EES,
  ROL_REP_EES_TEMP,
  ROL_ADMIN_SUBTEL,
  ROL_VAL_SUBTEL,
  ROL_MINEDUC_ADMIN,
  QUESTIONS,
} from "../../Constants/Constants";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function FasFirma() {
  const { pageTitle, setPageTitle, setDrawerHidding, userSesion, includeRol } =
    useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [submitConfirm, setSubmitConfirm] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [techTypes, setTechTypes] = useState([]);

  const [showQuestions, setShowQuestions] = useState(true);
  const [answers, setAnswers] = useState(Array(QUESTIONS.length).fill("no"));
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [documentVisible, setDocumentVisible] = useState(true);
  const [canSign, setCanSign] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const { id } = useParams();

  const links = [
    { name: "Inicio", path: "/home" },
    { name: "Listado FAS", path: "/fas_lista" },
    { name: "Firma FAS", path: "/fas_firma/" + id },
  ];

  const [formData, setFormData] = useState({
    rut: userSesion.id,
    operator_name: "",
    rbd: "",
    estado: "",
    ees_name: "",
    ees_managers: ["", ""],
    ees_temporal_manager_list: [""],
    ees_temporal_manager: "",
    tech_id: "",
    int_speed_down: "",
    installation_date: dayjs(),
    speed_up: "",
    speed_down: "",
    latency: "",
    packet_loss: "",
    evidence: [],
    answers: [],
    rejectOption: null,
  });

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
    setFormData((prevFormData) => ({
      ...prevFormData,
      answers: updatedAnswers,
    }));
  };

  const handleClick = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      rejectOption: prevFormData.rejectOption === value ? null : value,
    }));
  };

  function getTechType() {
    axios
      .getTechTypes()
      .then((response) => {
        let tempTechs = [];
        response.data.map((tech) => tempTechs.push(tech.nombre_enlace));
        setTechTypes(tempTechs);
      })
      .catch((error) => {});
  }
  const fetchData = async (query) => {
    setLoading(true);
    axios
      .getFas(id)
      .then((response) => {
        setFormData({
          ...response.data.datos,
          installation_date: dayjs(response.data.datos["installation_date"]),
          estado: response.data.estado,
        });
        const flag =
          includeRol([ROL_REP_EES, ROL_REP_EES_TEMP]) &&
          (response.data.estado === NO_EES ||
            response.data.estado === NO_EES_TEMP);
        setShowQuestions(flag);
        setAnswers(Array(QUESTIONS.length).fill(flag ? "no" : "yes"));
      })
      .catch((error) => {
        setDocumentVisible(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function singDoc(event) {
    event.preventDefault();
    if (!submitConfirm) {
      setSubmitConfirm(!submitConfirm);
      return;
    }
    const form = new FormData();
    const localFormData = {
      ...formData,
      installation_date: formData["installation_date"]
        .locale("es")
        .format("YYYY/MM/DD HH:MM:ss")
        .toString(),
    };

    form.append("data", JSON.stringify(localFormData));

    setLoadingButton(true);
    axios
      .signDoc("fas", id, form)
      .then((response) => {
        setModalError(false);
        setModalContent("Documento enviado y firmado");
        setOpen(true);
      })
      .catch((error) => {
        setModalError(true);
        setModalError("La firma del documento a fallado");
      })
      .finally(() => {
        setLoadingButton(false);
        setSubmitConfirm(false);
      });
  }

  function rejectDoc(event) {
    event.preventDefault();
    if (!submitConfirm) {
      setSubmitConfirm(!submitConfirm);
      return;
    }
    const form = new FormData();
    const localFormData = {
      ...formData,
      installation_date: formData["installation_date"]
        .locale("es")
        .format("YYYY/MM/DD HH:MM:ss")
        .toString(),
    };

    form.append("data", JSON.stringify(localFormData));
    setLoadingButton(true);
    axios
      .rejectDoc("fas", id, form)
      .then((response) => {
        navigate("/fas_lista");
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingButton(false);
        setSubmitConfirm(false);
      });
  }

  useEffect(() => {
    setPageTitle("Firma de formulario electrónico de Alta del Servicio (FAS)");
    getTechType();
    fetchData();
    setDrawerHidding(false);
    setPageLoaded(true);
  }, []);
  useEffect(() => {
    setIsSubmitEnabled(answers.every((answer) => answer === "yes"));
    setFormData((prevFormData) => ({
      ...prevFormData,
      answers: answers,
    }));
  }, [answers]);

  useEffect(() => {
    if (
      includeRol([ROL_REP_EES, ROL_REP_EES_TEMP]) &&
      (formData.estado === NO_EES || formData.estado === NO_EES_TEMP)
    ) {
      setCanSign(true);
    } else if (
      includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL]) &&
      formData.estado === NO_SUBTEL
    ) {
      setCanSign(true);
    } else if (
      includeRol([ROL_MINEDUC_ADMIN]) &&
      formData.estado === NO_MINEDUC
    ) {
      setCanSign(true);
    }
  }, [userSesion, formData]);

  if (pageLoaded) {
    return (
      <>
        <PageBody>
          <BreadcrumbStandard links={links} />
          {loading ? (
            <PageBgBlock pad="2rem 9rem">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                <CircularProgress />
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Cargando...
                </Typography>
              </Box>
            </PageBgBlock>
          ) : documentVisible && canSign ? (
            <>
              <PageBgBlock pad="2rem 9rem">
                <Stack alignItems="left" justifyContent="center" spacing={2}>
                  <Typography
                    variant="h1"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
                  >
                    FORMULARIO FAS
                  </Typography>
                  <Box
                    justifyContent="center"
                    sx={{ display: "flex", margin: "0.2rem!important" }}
                  >
                    <Typography
                      variant="small"
                      color="inherit"
                      sx={{
                        textAlign: "center",
                        justifyContent: "center",
                        paddingY: "0",
                        marginTop: "0",
                      }}
                    >
                      N° folio:{" "}
                      <Typography
                        variant="small"
                        sx={{ paddingLeft: "1rem", marginTop: "0" }}
                      >
                        {String(id || "").padStart(8, "0")}
                      </Typography>
                    </Typography>
                  </Box>

                  <Box
                    justifyContent="center"
                    sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
                  >
                    <Typography
                      variant="body"
                      align="center"
                      sx={{ marginBottom: "2rem" }}
                    >
                      Le presentamos el Formulario de Alta de Servicio, por
                      favor llene todos los campos indicados para un proceso
                      exitoso entre todos los involucrados (operador, EES,
                      SUBTEL y MINEDUC)
                    </Typography>
                  </Box>
                  <Typography
                    component="h1"
                    variant="h3"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                  >
                    DATOS DEL OPERADOR
                  </Typography>
                  <form>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <Grid container spacing={2}>
                        <Grid size={6}>
                          <TextField
                            disabled
                            id="outlined-required"
                            label="RUT Operador Instalador"
                            size="small"
                            sx={{ width: "100%" }}
                            value={formData.rut}
                          />
                        </Grid>
                        <Grid size={6}>
                          <TextField
                            disabled
                            id="outlined-required"
                            label="Operador"
                            size="small"
                            sx={{ width: "100%" }}
                            value={formData.operator_name}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>

                    <Typography
                      component="h1"
                      variant="h3"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      DATOS DEL EES
                    </Typography>

                    <FormControl sx={{ m: 1, minWidth: "100%", spacing: 2 }}>
                      <Grid container spacing={2}>
                        <Grid size={12}>
                          <TextField
                            disabled
                            id="outlined-required"
                            label="RBD"
                            size="small"
                            sx={{ width: "100%" }}
                            value={formData.rbd}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <Grid container spacing={2}>
                        <Grid size={12}>
                          <TextField
                            disabled
                            id="outlined"
                            label="Nombre del Colegio"
                            size="small"
                            value={formData.ees_name}
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid size={6}>
                          <TextField
                            label="Responsable del establecimiento"
                            size="small"
                            disabled
                            value={formData.ees_managers[0]}
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid size={6}>
                          <TextField
                            label="Responsable del establecimiento 2"
                            size="small"
                            disabled
                            value={formData.ees_managers[1]}
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <Grid
                        container
                        sx={{
                          width: "100%",
                          background: checked ? "#BBDEFB" : "#E4E4E4",
                          borderRadius: "0.5rem",
                          padding: "2%",
                        }}
                        spacing={2}
                      >
                        <Grid size={6}>
                          <Grid container spacing={2}>
                            <Grid sx={{ width: "100%" }} size={12}>
                              <FormControlLabel
                                control={
                                  <Switch disabled checked={formData.checked} />
                                }
                                label="Administrador temporal"
                              />
                            </Grid>
                            <Grid sx={{ width: "100%" }} size={12}>
                              <TextField
                                disabled
                                required
                                id="outlined-required"
                                label="RUT Administrador temporal"
                                size="small"
                                helperText="(12345678-9)"
                                value={formData.ees_temporal_manager}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid size={6}>
                          <Typography color="inherit" sx={{ fontSize: "12px" }}>
                            *Si por fuerza mayor el administrador del colegio no
                            se encuentra en el establecimiento debe ingresar a
                            un Administrador temporal que cumpla el rol de
                            ministro de fe del proceso y debe contar con Clave
                            Única para firmar el documento de FAS.
                          </Typography>
                        </Grid>
                      </Grid>
                    </FormControl>

                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      DATOS DE CONEXIÓN
                    </Typography>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <Grid container spacing={2}>
                        <Grid size={12}>
                          <InputLabel size="small" id="tech_id">
                            Tecnología
                          </InputLabel>
                          <Select
                            required
                            disabled
                            value={formData.tech_id}
                            labelId="tech_id"
                            defaultValue={0}
                            label="Tecnología"
                            size="small"
                            sx={{ width: "100%" }}
                          >
                            {techTypes.map((tech, index) => (
                              <MenuItem key={index + "menu"} value={index}>
                                {tech}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid size={6}>
                          <NumericFormat
                            required
                            disabled
                            customInput={TextField}
                            label="Vel. subida total Nacional (Mbps)"
                            value={formData.speed_up}
                            allowNegative={false}
                            size="small"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid size={6}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"es"}
                            localeText={
                              esES.components.MuiLocalizationProvider
                                .defaultProps.localeText
                            }
                          >
                            <DateTimePicker
                              disabled
                              label="Fecha instalación"
                              slotProps={{ textField: { size: "small" } }}
                              value={formData.installation_date}
                              size="small"
                              sx={{ width: "100%" }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <Grid container spacing={2}>
                        <Grid size={6}>
                          <NumericFormat
                            required
                            disabled
                            customInput={TextField}
                            label="Vel. bajada total Nacional (Mbps)"
                            value={formData.speed_down}
                            allowNegative={false}
                            size="small"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid size={6}>
                          <NumericFormat
                            required
                            disabled
                            customInput={TextField}
                            label="Vel. bajada total Internacional (Mbps)"
                            value={formData.int_speed_down}
                            size="small"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <Grid container spacing={2}>
                        <Grid size={6}>
                          <NumericFormat
                            disabled
                            required
                            customInput={TextField}
                            label="Latencia (ms)"
                            value={formData.latency}
                            allowNegative={false}
                            size="small"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid size={6}>
                          <NumericFormat
                            required
                            disabled
                            customInput={TextField}
                            label="Pérdida de paquetes (%)"
                            value={formData.packet_loss}
                            size="small"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: "100%" }}>
                      <Paper
                        elevation={1}
                        sx={{ width: "100%", borderRadius: "0.5rem" }}
                      >
                        <Grid container spacing={2}>
                          <Grid
                            display="flex"
                            justifyContent="flex-start"
                            size={12}
                            sx={{ maxHeight: "small" }}
                          >
                            <Typography
                              variant="body"
                              sx={{ padding: "0.5rem" }}
                            >
                              <Grid container spacing={2}>
                                <Grid
                                  display="flex"
                                  justifyContent="flex-start"
                                  size={12}
                                  sx={{ maxHeight: "small" }}
                                >
                                  <CollectionsOutlinedIcon
                                    sx={{ marginRight: "0.5rem" }}
                                  />{" "}
                                  {"Subir imágenes de evidencia."}
                                </Grid>
                              </Grid>
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <Divider orientation="vertical" flexItem />
                            <LoadingButton
                              disabled
                              component="label"
                              role={undefined}
                              variant="text"
                              tabIndex={-1}
                              startIcon={<FileUploadOutlinedIcon />}
                              sx={{
                                textDecoration: "underline",
                                marginLeft: "1%",
                              }}
                            >
                              Subir imágenes
                              <VisuallyHiddenInput
                                type="file"
                                accept="image/*"
                                multiple
                              />
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Paper>
                    </FormControl>
                  </form>
                  <ImageGallery images={formData.evidence}></ImageGallery>
                </Stack>
              </PageBgBlock>

              <Stack justifyContent="center" spacing={2}>
                <Box>
                  <List
                    sx={{
                      backgroundColor: "#BBDEFB",
                      padding: "1rem 0",
                      borderRadius: "20px",
                      marginTop: "1rem",
                    }}
                  >
                    <ListItem key="header" sx={{ justifyContent: "center" }}>
                      <Typography variant="h1" align="center">
                        ENCUESTA DE SATISFACCIÓN EES
                      </Typography>
                    </ListItem>
                    <Divider sx={{ border: "1px solid white" }} />
                    {QUESTIONS.map((question, index) => (
                      <React.Fragment key={index}>
                        <ListItem
                          sx={{ paddingLeft: "9rem", paddingRight: "9rem" }}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{ dispaly: "flex", width: "100%" }}
                          >
                            <Grid size={9}>
                              <ListItemText
                                primary={
                                  <Typography variant="body1">
                                    {question}
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid
                              size={3}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <RadioGroup
                                disabled={!showQuestions}
                                row
                                value={answers[index]}
                                onChange={(event) =>
                                  handleAnswerChange(index, event.target.value)
                                }
                              >
                                <FormControlLabel
                                  disabled={!showQuestions}
                                  value="yes"
                                  control={<Radio />}
                                  label="Sí"
                                />
                                <FormControlLabel
                                  disabled={!showQuestions}
                                  value="no"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </ListItem>
                        {index < QUESTIONS.length - 1 && (
                          <Divider sx={{ border: "1px solid white" }} />
                        )}{" "}
                      </React.Fragment>
                    ))}
                  </List>
                </Box>

                {includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL]) &&
                formData.estado === NO_SUBTEL ? (
                  <PageBgBlock pad="2rem 9rem">
                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      CAUSALES DE RECHAZO
                    </Typography>

                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <RadioGroup
                        name="opciones rechazo"
                        value={formData.rejectOption || ""}
                      >
                        {REJECT_F.map((reject, index) => (
                          <FormControlLabel
                            key={index}
                            value={reject}
                            control={
                              <Radio onClick={() => handleClick(reject)} />
                            }
                            label={reject}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>

                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      JUSTIFICACIÓN DE SUBTEL
                    </Typography>

                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <TextField
                        required
                        multiline
                        minRows={4}
                        maxRows={30}
                        id="outlined-required"
                        placeholder="Texto aquí"
                        size="small"
                        value={formData.justificacion_subtel}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            justificacion_subtel: event.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </PageBgBlock>
                ) : null}

                <Grid container spacing={2} sx={{ paddingTop: "2rem" }}>
                  {submitConfirm ? (
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size={12}
                    >
                      <Alert
                        icon={false}
                        variant="filled"
                        severity="warning"
                        display="flex"
                        justifyContent="center"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          "& .MuiAlert-message": {
                            textAlign: "center",
                            width: "inherit",
                          },
                        }}
                      >
                        <Typography
                          variant="body"
                          color="inherit"
                          sx={{ textAlign: "center" }}
                        >
                          Revise que todos los campos contienen la información
                          correcta antes de firmar el formulario FAS. Al hacer
                          clic en 'Firmar y enviar', el documento quedará
                          firmado sin opción de modificación.
                        </Typography>
                      </Alert>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid size={2}></Grid>
                  <Grid size={4}>
                    <PageButtonWhite
                      onClick={rejectDoc}
                      loading={loadingButton || !formData.rejectOption}
                      width="12rem"
                    >
                      {" "}
                      Rechazar
                    </PageButtonWhite>
                  </Grid>
                  <Grid size={4}>
                    <PageButtonBlack
                      onClick={singDoc}
                      loading={
                        !isSubmitEnabled ||
                        loadingButton ||
                        formData.rejectOption
                      }
                      justifyContent="center"
                      width="12rem"
                    >
                      Firmar y enviar
                    </PageButtonBlack>
                  </Grid>
                  <Grid size={2}></Grid>
                </Grid>
              </Stack>
            </>
          ) : (
            <PageBgBlock pad="4rem 9rem">
              <Box align="center">
                <ErrorOutlineIcon color="error" sx={{ fontSize: 45 }} />
              </Box>
              <Typography
                align="center"
                variant="h1"
                component="h1"
                fontWeight="bold"
                color="textPrimary"
              >
                No disponible
              </Typography>

              <Box
                justifyContent="center"
                sx={{ maxWidth: "500px", margin: "0 auto", display: "flex" }}
              >
                <Typography
                  variant="body"
                  align="center"
                  sx={{ marginBottom: "20px" }}
                >
                  El documento al que intenta acceder no esta diponible para ser
                  firmado.
                </Typography>
              </Box>
            </PageBgBlock>
          )}
          <ModalStandard
            open={open}
            error={modalError}
            title={modalContent}
            btnText="Volver a listado"
            onClick={() => navigate("/fas_lista")}
            onClose={handleClose}
          ></ModalStandard>
        </PageBody>
      </>
    );
  }
}
